function hexToRgb (hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null
}

export function toRgb (color: string) {
  if (color.startsWith('rgb')) {
    return color
      .replace(/[a-z()]/gi, '')
      .split(/[\s,]+/)
      .slice(0, 3)
  }
  return hexToRgb(color) || []
}