import { Modal, Text, Icon } from '../common'
import { style } from './style'
import { userInfoData } from '../../hooks/useAuth'

type OrganizationsModalProps = {
  userInfo?: userInfoData,
  active?: boolean,
  changingOrganization?: boolean,
  onClose?: () => void,
  onSwitchOrganization?: (uuid: string) => void
}

export const OrganizationsModal = ({
  active = false,
  changingOrganization = false,
  userInfo,
  onClose,
  onSwitchOrganization
} : OrganizationsModalProps) => {

  function onClickItem(org: any) {
    if (org.current || changingOrganization) return
    onSwitchOrganization && onSwitchOrganization(org.organization_uuid)
  }
  
  return (
    <Modal
      active={active}
      align="start"
      contentClass="p-10"
      width="460"
      onClickOutside={onClose}
    >
      <style>{style}</style>
      <Icon
        name="icon-close"
        className="icon-close"
        size="24"
        onClick={onClose}
        style={{ zIndex: 1 }}
      />
      <Text
        tag="h3"
        className="mb-5"
        size="xl"
        weigth="bold"
      >
        {userInfo?.translations?.title_select_organizations}
      </Text>
      <Text tag="p">
        {userInfo?.translations?.body_select_organizations}
      </Text>
      <div className="section-organizations">
        {(userInfo?.organizations?.options ?? []).map((org) => (
          <div
            key={org.organization_uuid}
            className={org.current || changingOrganization ? 'disabled' : ''}
            onClick={() => onClickItem(org)}
          >
            <img
              src={org.organization_logo}
              alt={org.organization_username}
            />
          </div>
        ))}
      </div>
    </Modal>
  )
}