import { useEffect, useState } from 'react'
import type { LanguageType } from './auth.d.ts'

type DayAndMonthNamesInterface = {
  [key in LanguageType]: string[]
}

const dayNames: DayAndMonthNamesInterface = {
  es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  ca: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds']
}
const monthNames: DayAndMonthNamesInterface = {
  es: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ],
  ca: [
    'gen',
    'febr',
    'març',
    'abr',
    'maig',
    'juny',
    'jul',
    'ag',
    'set',
    'oct',
    'nov',
    'des'
  ]
}

function getFormattedDate({ language }: { language: LanguageType }) {
  if (!language) return
  const date = new Date()
  const dayOfWeek = dayNames[language][date.getDay()]
  const day = date.getDate()
  const month = monthNames[language][date.getMonth()]
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${dayOfWeek} ${day} ${month} ${hours}:${minutes}`
}

export function useClock({ language }: { language: LanguageType }) {
  const [time, setTime] = useState(getFormattedDate({ language }))
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getFormattedDate({ language }))
    }, 60000)
    // Limpiar intervalo al desmontar el componente
    return () => clearInterval(intervalId)
  }, [language])
  return time
}
