import { makeClassName, convertToUnit } from '../../utils'
import { useColors } from '../../hooks/useColors'
import type { MergeComponentProps } from '../../utils'

export type IconProps = MergeComponentProps<
  'span',
  {
    className?: string,
    color?: string,
    size?: string | number | null | undefined
  }
>

export const IconBase = ({
  className,
  children,
  color,
  size = '24',
  style,
  ...props
} : IconProps) => (
  <span
    style={{ ...style, fontSize: convertToUnit(size) }}
    className={makeClassName([
      'topbar-icon',
      size && `text-${size}`,
      useColors({ color }),
      className
    ])}
    {...props}
  >
    {children}
  </span>
)