import { useState, useEffect, useRef, createRef, RefObject } from "react"
import { UrlsAddressess } from '../../hooks/urls'


import {
    useFetchNotifications,
    useUnreadCount,
    useSocket,
    useMarkNotificationsAs,
    useRemoveNotification,
    useRemoveAllNotifications,
    IMessage
  } from "@novu/notification-center"

export interface Notification extends IMessage{
  ref: null | RefObject<HTMLDivElement>
  deleting: boolean | undefined
  entering: boolean | undefined
}
export type Feed = {
  _id: string
  name: string
  identifier: string
  _organizationId: string
  _environmentId: string
  deleted: false
  createdAt: string
  updatedAt: string
  selected?: boolean,
  isGeneral?: boolean
}

const feedGeneral: Feed = {
  "_id": "general",
  "name": "General",
  "identifier": "General",
  "_organizationId": "1",
  "_environmentId": "1",
  "deleted": false,
  "createdAt": "2024-10-11T10:35:16.295Z",
  "updatedAt": "2024-10-11T10:35:16.295Z",
  selected: true,
  isGeneral: true
}

const fmtCountTab = (count: number, limit: number) => {
  if (count && count > 0){
    return (count > limit) ? `${limit}+` : String(count)
  } else {
    return ''
  }
}

export function useCount() {
  const { socket } = useSocket()
  const [unReadCount, setUnreadCount] = useState<number>(0)
  const { data: unreadData, refetch: refetchUnreadCount } = useUnreadCount()

  useEffect(() => {
    setUnreadCount(unreadData?.count || 0)
  }, [unreadData])

  useEffect(() => {
    socket?.on('unread_count_changed', (data) => {
      setUnreadCount(data?.unreadCount || 0)
    })
  }, [])

  return {
    unReadCount,
    refetchUnreadCount
  }
}
const REFRESH_TIME = 200
export function useFeeds({ userToken, urls }: { userToken: string, urls: UrlsAddressess }) {
  const [notifications, setNotifications] = useState<(Notification)[]>([])
  const [feeds, setFeeds] = useState<Feed[]>([feedGeneral])
  const [selectedFeed, setSelectedFeed] = useState<Feed>(feedGeneral)
  const [isRead, setIsRead] = useState<boolean>(false)
  const firstEffect = useRef({ effectFeed: false, effectRead: false})
  const [unReadCountTab, setUnreadCountTab] = useState<string>('')
  const [mapSet, setMapSet] = useState<Map<string, RefObject<HTMLDivElement>>>(new Map())
  const { unReadCount } = useCount()
  const { socket } = useSocket()
  const { markNotificationsAs } = useMarkNotificationsAs()
  const { removeNotification } = useRemoveNotification()
  const { removeAllNotifications } = useRemoveAllNotifications()
  const {
    data,
    hasNextPage,
    isLoading,
    fetchNextPage,
    refetch,
  } = useFetchNotifications(
    {
      query: {
        limit: 10,
        read: isRead,
        ... (selectedFeed.isGeneral ) ? { } : { feedIdentifier: selectedFeed.identifier }
      }

    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  )

  useEffect(() => {
    // console.log('unReadCount: --->', unReadCount)
    if (selectedFeed.isGeneral) {
      setUnreadCountTab(fmtCountTab(unReadCount || 0, 100))
    } 
  }, [unReadCount])

  // on change data
  useEffect(() => {
    // setNotifications([])
    let pagesUnion :Notification[]  = []
    data?.pages.forEach((page) => {
      const tmp = page.data as Notification[]
      pagesUnion = [...pagesUnion, ...tmp] 
      // setNotifications(() => [...tmp])
    })

    setNotifications((old) => {
      console.log('old', old.length, 'pageUnion', pagesUnion.length  )
      // if (old.length === pagesUnion.length) return old
      return pagesUnion
    })

    if (selectedFeed.isGeneral && !isRead) {
      setUnreadCountTab(fmtCountTab(unReadCount || 0, 100))
    } else if (!selectedFeed.isGeneral && !isRead) {
      setUnreadCountTab(fmtCountTab(data?.pages[0].totalCount || 0, 9))
    }
  }, [data])
  
  useEffect(() => {
    notifications.forEach((notification) => {
      if (!mapSet.has(notification._id)) {
        const ref = createRef<HTMLDivElement>()
        mapSet.set(notification._id, ref)
        notification.ref = ref
      } else {
        notification.ref = mapSet.get(notification._id) || null
      }
    })
    setMapSet(mapSet)
    setNotifications(notifications)
  }, [notifications, mapSet])

  useEffect(() => {
    if (firstEffect.current?.effectFeed) {
      // tab count
      refetch()
      return
    }
    firstEffect.current.effectFeed = true
  }, [selectedFeed])
  
  useEffect(() => {
    if (firstEffect.current?.effectRead) {
      refetch()
      return
    }
    firstEffect.current.effectRead = true
  }, [isRead])
  
  const fetchFeeds = async () => {
    await fetch(`${urls.url_notifications_feeds}?token=${userToken}`)
    .then(response => response.json())
    .then(data => {
      setFeeds([feedGeneral, ...data])
    })
  }

  const fetchNotifications = () => {
    refetch()
  }

  const selectFeed = (feed: Feed) => {
    setIsRead(false)
    setSelectedFeed(feed)
    setFeeds((feeds) => {
      feeds.forEach((f) => {
        f.selected = feed.identifier === f.identifier 
      })
      return feeds
    })
  }

  const selectIsRead = (isRead: boolean) => {
    setIsRead(isRead)
  }

  const markAsRead = (notification: IMessage) => {
    markNotificationsAs({ messageId: notification._id , seen: true, read: true}, { onSuccess: () => {
      setTimeout(() => {
        refetch()
      }, REFRESH_TIME)
    }})
  }

  const markAsUnRead = (notification: IMessage) => {
    markNotificationsAs({ messageId: notification._id , seen: false, read: false}, { onSuccess: () => {
      setTimeout(() => {
        refetch()
      }, REFRESH_TIME)
    }})
  }

  const markAllAsRead = () => {
    const ids = notifications.map((n) => n._id)
    markNotificationsAs({ messageId: ids , seen: true, read: true}, { onSuccess: () => {
      refetch()
    }})
  }

  const deleteNotification = (notification: IMessage) => {
    removeNotification({ messageId: notification._id}, { onSuccess: () => {
      setNotifications((notifications) =>{
        const index = notifications.findIndex(n => n._id === notification._id)
        notifications[index].deleting = true
        return notifications
      })
      setTimeout(() => {
        refetch()
      }, REFRESH_TIME * 2)
    }})
  }

  const deleteAllNotifications = () => {
    removeAllNotifications({ 
      ... (selectedFeed.isGeneral ) ? { } : { feedId: selectedFeed._id }
    }, { onSuccess: () => {
      refetch()
    }})
  }

  useEffect(() => {
    fetchFeeds()
    fetchNotifications()
    socket?.on('notification_received', () => {
      refetch()
    })

  }, [])
  
  return {
    feeds,
    hasNextPage,
    fetchNextPage,
    notifications,
    isLoading,
    selectFeed,
    selectIsRead,
    unReadCountTab,
    isRead,
    selectedFeed,
    fetchFeeds,
    fetchNotifications,
    markAsRead,
    markAllAsRead,
    deleteNotification,
    deleteAllNotifications,
    markAsUnRead
  }
}