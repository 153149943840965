import { useMemo, useCallback, useRef } from 'react'
import { Btn, Dropdown, Icon } from './common'
import type { DropdownProps, DropdownItemProps } from './common/Dropdown'
import type { TopbarMenu, TopbarMenuItem } from '../config'

type AbstractProps = TopbarMenu & {
  icon?: string,
  onClickItem: (item: TopbarMenuItem) => void
}

export type ConfigDropdownProps = Omit<DropdownProps, keyof AbstractProps> & AbstractProps

export type ConfigDropdownItemsProps = DropdownItemProps<DropdownProps> & { raw: TopbarMenuItem }

const makeIcon = (iconType: 'iconLeft' | 'iconRight', option: TopbarMenuItem) => {
  if (!option[iconType]) return ''
  if (!option.active) return option[iconType]
  return (
    <Icon
      name={option[iconType] as string}
      className="append-icon text-primary"
      size="20"
    />
  )
}

const configOptionsToDropdownItems = (options: TopbarMenuItem[]) => options.map((option, index) => {
  const id = option.id || (option.eventName ? `config-${option.eventName}` : index)
  const item = {
    id,
    text: option.text,
    tag: option.tag,
    prependIcon: makeIcon('iconLeft', option),
    appendIcon: makeIcon('iconRight', option),
    disabled: option.disabled,
    clickDisabled: option.clickDisabled,
    raw: option
  } as ConfigDropdownItemsProps
  if (option.type === 'menu' && option.options?.length) {
    item.dropdown = {
      position: 'right',
      align: 'start',
      offsetX: '5',
      width: option.menuWidth,
      activeMode: option.activeMode,
      items: configOptionsToDropdownItems(option.options)
    }
  }
  return item
})

export const ConfigDropdown = ({
  className,
  text,
  version,
  // theme,
  options = [],
  activeMode = 'click',
  position = 'bottom',
  align = 'end',
  width = '235',
  offsetY = "10",
  icon = 'icon-plus-outline',
  onClickItem,
  ...props
}: ConfigDropdownProps) => {

  const Prepend = useMemo(() => {
    if (!text && !version) return undefined
    return (
      <div className="config-dropdown-prepend">
        {text && (
          <h6
            className="text-sm mb-1"
            dangerouslySetInnerHTML={{ __html: text as string }}
          />
        )}
        {version && (
          <p className="text-normal text-xs mb-0">
            {version}
          </p>
        )}
      </div>
    )
  }, [text, version])

  const items = useMemo(() => {
    return configOptionsToDropdownItems(options)
  }, [options])

  const ref = useRef<HTMLElement>(null)

  const onClickItemHandler = useCallback((item: ConfigDropdownItemsProps) => {
    if ((item.disabled && item.clickDisabled !== true) || item.id == null) return
    onClickItem && onClickItem(item.raw)
    if (item.raw.closeOnClick !== false) {
      const activeElement = document.activeElement as HTMLElement
      activeElement?.blur() // Close dropdown
    }
  }, [onClickItem])

  return (
    <Dropdown
      ref={ref}
      {...props}
      style={{ height: '100%' }}
      className={['config-dropdown', className]}
      prepend={Prepend}
      activeMode={activeMode}
      position={position}
      align={align}
      width={width}
      offsetY={offsetY}
      items={items}
      onClickItem={onClickItemHandler}
    >
      <Btn
        className="rounded-0"
        variant="tonal"
        color="base"
        width="50"
        height="100%"
        data-test="dropdown-config"
      >
        <Icon name={icon} size="xl"/>
      </Btn>
    </Dropdown>
  )
}