import { useState, useEffect, MutableRefObject } from 'react'
import { $fetch, emitEvent } from '../utils'

export type useOrganizationsProps = {
  ref?: MutableRefObject<HTMLDivElement | null>,
  state?: string
}

export type switchOrganizationPayload = {
  url: string,
  uuid: string,
  token: string,
  language?: string
}

export function useOrganizations ({
  ref,
  state
} : useOrganizationsProps = {}) {

  const [openModalOrganizations, setOpenModalOrganizations] = useState<boolean>(false)
  const [pending, setPending] = useState<boolean>(false)

  async function switchOrganization ({
    url,
    token,
    uuid,
    language = 'es'
  } : switchOrganizationPayload) {
    try {
      if (!url || !token || !uuid) return
      setPending(true)
      const data = await $fetch({
        url: `${url}/${uuid}?token=${token}`,
        language,
        state
      })
      if (data.code === 404) return emitEvent(ref?.current, 'organization-switch-failed')
      if (data.url) {
        window.open(
          data.url,
          window.location.hostname.includes('farmacloud.io') ? '_self' : '_blank',
          'noreferrer'
        )
        emitEvent(ref?.current, 'organization-switched')
        setOpenModalOrganizations(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    if (openModalOrganizations) {
      emitEvent(ref?.current, 'open-environments')
    }
  }, [openModalOrganizations])

  return {
    openModalOrganizations,
    pending,
    setOpenModalOrganizations,
    switchOrganization
  }
}