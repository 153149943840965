import { Box } from './common'
import { CSSTransition } from 'react-transition-group'
import { Player } from '@lordicon/react'
import shieldSecurityIcon from '../assets/lordicons/shield-security.json'
import { useState, useRef, useEffect } from 'react'

export const SecurityMessage = () => {
  const playerRef = useRef<Player>(null)
  const msgRef = useRef<HTMLDivElement>(null)
  const msgTextRef = useRef<HTMLDivElement>(null)
  const [msgStep, setMsgStep] = useState<number>(0)

  function startMessageTransition (step = 0) {
    if (step === 5) return setMsgStep(0)
    setMsgStep(step + 1)
    setTimeout(() => startMessageTransition(step + 1), 1000)
  }

  useEffect(() => {
    startMessageTransition()
  }, [])

  useEffect(() => {
    if (msgStep === 5) {
      playerRef.current?.playFromBeginning()
    }
  }, [msgStep])

  return (
    <CSSTransition
      nodeRef={msgRef}
      in={!!msgStep}
      timeout={500}
      classNames="slide-bottom"
      unmountOnExit
      onEntered={() => playerRef.current?.playFromBeginning()}
    >
      <Box
        ref={msgRef}
        className="topbar-message"
        bgColor="success"
        color="white"
        height={40}
      >
        <span className="lordicon-bold mx-2">
          <Player
            ref={playerRef} 
            icon={shieldSecurityIcon}
            size={30}
          />
        </span>
        <CSSTransition
          nodeRef={msgTextRef}
          in={msgStep > 0 && msgStep < 4}
          timeout={500}
          classNames="fade-text"
          unmountOnExit
        >
          <span className="text-medium" ref={msgTextRef}>
            Tu cuenta ha sido segurizada
          </span>
        </CSSTransition>
      </Box>
    </CSSTransition>
  )
}