import { useState, useEffect, MutableRefObject } from 'react'
import { $fetch, emitEvent } from '../utils'
import { Urls } from './urls'

export type useLockScreenProps = {
  ref?: MutableRefObject<HTMLDivElement | null>,
  env?: string,
  urls?: Urls,
  state?: string,
  onChangeToken?: (token: string) => void
}

export type unlockScreenPayload = {
  url: string,
  token: string,
  pin: string,
  language?: string
}

export function useLockScreen ({
  ref,
  env,
  urls,
  state,
  onChangeToken
} : useLockScreenProps = {}) {

  const [showLockScreen, setShowLockScreen] = useState<boolean>(false)
  const [lockScreenValue, setLockScreenValue] = useState<string>('')
  const [LockScreenValid, setLockScreenValid] = useState<boolean | null>(null)
  const [LockScreenDisabled, setLockScreenDisabled] = useState<boolean>(false)

  async function blockSession (token: string) {
    const url = urls?.[env as keyof typeof urls]?.url_switch_user ?? ''
    if (!url || !token) return
    return $fetch({ url: `${url}?token=${token}`, state })
  }

  async function unlockScreen ({
    url,
    token,
    pin,
    language = 'es'
  } : unlockScreenPayload) {
    setLockScreenValid(null)
    setLockScreenValue(pin)
    if (!url || !token || LockScreenDisabled || pin.length < 6) return
    try {
      setLockScreenDisabled(true)
      const data = await $fetch({
        // urls[env as keyof typeof urls]?.url_switch_user
        url: `${url}/${pin}?token=${token}`,
        language,
        state
      })
      if (!data.jti) {
        setLockScreenValid(false)
        setTimeout(() => {
          setLockScreenValid(null)
          setLockScreenValue('')
        }, 1000)
        return
      }
      setLockScreenValid(true)
      setTimeout(() => {
        setShowLockScreen(false)
        // Reset Token
        if (data.jti !== token) {
          onChangeToken && onChangeToken(data.jti)
          emitEvent(ref?.current, 'switch-user', data.jti)
        }
      }, 1000)
    } catch (error) {
      console.error(error)
      setLockScreenValid(false)
      setLockScreenValue('')
    } finally {
      setLockScreenDisabled(false)
    }
    return true
  }

  // Emit lock/unlock event
  useEffect(() => {
    setLockScreenValue('')
    if (showLockScreen) setLockScreenValid(null)
    const eventName = showLockScreen ? 'screen-locked' : 'screen-unlocked'
    emitEvent(ref?.current, eventName)
  }, [showLockScreen])

  return {
    showLockScreen,
    lockScreenValue,
    LockScreenValid,
    LockScreenDisabled,
    blockSession,
    setShowLockScreen,
    setLockScreenValid,
    setLockScreenDisabled,
    unlockScreen
  }
}