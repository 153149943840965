import { useFetchUserPreferences, IUserPreferenceSettings, useUpdateUserPreferences } from "@novu/notification-center"
import { useState, useEffect } from "react"

export function useSettings() {
    const [settings, setSettings ] = useState<IUserPreferenceSettings[]>([])
    const { data, refetch } = useFetchUserPreferences()
    const { updateUserPreferences } = useUpdateUserPreferences()
    useEffect(() => {
      if (data) {
        console.log(data[0].template.name, data[0].preference.channels.email)
        setSettings(data)
      }
    }, [data])

    const setUserPreference = (templateId: string, channelType: string, checked: boolean) => {
      // {
      //   templateId: string; // workflowId of mongodbId type
      //   channelType: string;
      //   checked: boolean;
      // }
      updateUserPreferences({
        templateId,
        channelType,
        checked
      })
    }
    useEffect(() => {
      refetch()
    }, [])
    return {
        settings,
        updateUserSettings: setUserPreference
    }
}