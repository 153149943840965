import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export const IconChevronRight = (props: IconProps) => (
  <IconBase {...props}>
    <svg
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M8.22624 14.2591C8.22624 14.0457 8.30791 13.8324 8.47041 13.6699L11.2246 10.9157L8.57457 8.17155C8.25457 7.83988 8.26291 7.31322 8.59541 6.99322C8.92624 6.67322 9.45457 6.68238 9.77374 7.01322L12.9921 10.3466C13.3079 10.6741 13.3037 11.1941 12.9821 11.5149L9.64874 14.8482C9.32291 15.1741 8.79624 15.1741 8.47041 14.8482C8.30791 14.6857 8.22624 14.4724 8.22624 14.2591Z"
      />
    </svg>
  </IconBase>
)