export const style = `
.input-pin {
  display: flex;
  justify-content: space-between;
}
.input-pin > input {
  flex: 0 0 auto;
  width: 40px;
  border-radius: 6px;
  height: 50px; 
  text-align: center;
  font-size: 24px;
}
.input-pin > input,
.input-pin > input:disabled {
  border: 1px solid #E2E2E2;
  background-color: white;
  color: #E2E2E2;
}
.input-pin > input:not(:placeholder-shown),
.input-pin > input:disabled:not(:placeholder-shown) {
  color: rgb(var(--topbar-primary));
  background-color: rgba(var(--topbar-primary), 0.1);
  border-color: transparent;
}
.input-pin > input:focus,
.input-pin > input:focus:disabled {
  color: rgb(var(--topbar-primary));
  background-color: rgba(var(--topbar-primary), 0.1);
  border-color: rgb(var(--topbar-primary));
  text-decoration: underline;
  text-underline-offset: 8px;
}
.input-pin > input:disabled {
  opacity: 0.7 !important;
}
.input-pin.invalid > input,
.input-pin.invalid > input:disabled {
  color: rgb(var(--topbar-error));
  border-color: rgb(var(--topbar-error));
  background-color: rgba(var(--topbar-error), 0.1);
}
.input-pin.valid > input,
.input-pin.valid > input:disabled {
  color: rgb(var(--topbar-success));
  border-color: rgb(var(--topbar-success));
  background-color: rgba(var(--topbar-success), 0.1);
}
`
