import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export const IconCheckFill = (props: IconProps) => (
  <IconBase {...props}>
    <svg
      viewBox="0 0 13 13"
      width="25"
      height="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_5264_23785" fill="white">
        <path 
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.03613 0.125C2.72233 0.125 0.0361328 2.8118 0.0361328 6.125C0.0361328 9.4382 2.72233 12.125 6.03613 12.125C9.34993 12.125 12.0361 9.4382 12.0361 6.125C12.0361 2.8118 9.34993 0.125 6.03613 0.125Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M6.03613 0.125C2.72233 0.125 0.0361328 2.8118 0.0361328 6.125C0.0361328 9.4382 2.72233 12.125 6.03613 12.125C9.34993 12.125 12.0361 9.4382 12.0361 6.125C12.0361 2.8118 9.34993 0.125 6.03613 0.125Z"
      />
      <path
        d="M6.03613 -0.875C2.16998 -0.875 -0.963867 2.25958 -0.963867 6.125H1.03613C1.03613 3.36402 3.27468 1.125 6.03613 1.125V-0.875ZM-0.963867 6.125C-0.963867 9.99042 2.16998 13.125 6.03613 13.125V11.125C3.27468 11.125 1.03613 8.88598 1.03613 6.125H-0.963867ZM6.03613 13.125C9.90228 13.125 13.0361 9.99042 13.0361 6.125H11.0361C11.0361 8.88598 8.79758 11.125 6.03613 11.125V13.125ZM13.0361 6.125C13.0361 2.25958 9.90228 -0.875 6.03613 -0.875V1.125C8.79758 1.125 11.0361 3.36402 11.0361 6.125H13.0361Z"
        fill="white"
        mask="url(#path-1-inside-1_5264_23785)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65888 3.96322L5.38848 6.94462L4.40868 5.69302C4.20468 5.43142 3.82668 5.38582 3.56628 5.59042C3.30528 5.79442 3.25908 6.17182 3.46368 6.43222L4.92228 8.29642C5.03628 8.44162 5.21028 8.52622 5.39508 8.52622H5.39928C5.58468 8.52562 5.75988 8.43802 5.87208 8.28982L8.61348 4.68982C8.81448 4.42582 8.76348 4.05022 8.49948 3.84922C8.23488 3.64822 7.85928 3.69982 7.65888 3.96322Z"
        fill="white"
      />
    </svg>
  </IconBase>
)