export const style = `
.topbar-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6000;
  padding: 20px;
  display: flex;  
  justify-content: center;
  transition: background 0.3s;
  background-color: rgba(0, 0, 0, 0);
}
.topbar-modal.align-start {
  align-items: flex-start;
  padding-top: 100px;
}
.topbar-modal.align-center {
  align-items: flex-center;
}
.topbar-modal.align-end {
  align-items: flex-end;
  padding-bottom: 100px;
}
.topbar-modal-content {
  max-width: 100%;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(0.9);
  opacity: 0;
}
.topbar-modal:not(.transparent).active {
  background-color: rgba(0, 0, 0, 0.5);
}
.topbar-modal.active .topbar-modal-content {
  transform: scale(1);
  opacity: 1;
}
.topbar-modal.active.blur {
  background-color: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(8px);
  --webkit-backdrop-filter: blur(10px);
  --moz-backdrop-filter: blur(8px);
}
`