import { convertToUnit } from '../utils'

export type useDimensionsProps = {
  width?: string | number | null | undefined,
  maxWidth?: string | number | null | undefined,
  minWidth?: string | number | null | undefined,
  height?: string | number | null | undefined,
  maxHeight?: string | number | null | undefined,
  minHeight?: string | number | null | undefined
}

export type Dimensions = {
  width?: string,
  maxWidth?: string,
  minWidth?: string,
  height?: string,
  maxHeight?: string,
  minHeight?: string
}

export function useDimensions (props: useDimensionsProps = {}) {
  return Object.keys(props).reduce((obj: Dimensions, key) => {
    const val = convertToUnit(props[key as keyof useDimensionsProps])
    if (val) obj[key as keyof Dimensions] = val
    return obj
  }, {})
}