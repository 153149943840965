import { InputPin } from './InputPin'
import { Modal, Text, Btn, Icon } from '../common'
import { style } from './style'
import { userInfoData } from '../../hooks/useAuth'

type LockScreenModalProps = {
  value?: string,
  active?: boolean,
  disabled?: boolean,
  valid?: boolean | null,
  userInfo?: userInfoData,
  onChange?: (value: string) => void,
  onClickButton?: (e: Event) => void,
  onClickLogout?: (e: Event) => void
}

export const LockScreenModal = ({
  value = '',
  active = false,
  disabled = false,
  valid,
  userInfo,
  onChange,
  // onClickButton,
  onClickLogout
} : LockScreenModalProps) => {

  const hint = valid == null
    ? ''
    : valid
      ? userInfo?.translations.switch_user_pin_valid
      : userInfo?.translations.switch_user_pin_invalid

  const hintStyle = valid == null ? '' : valid ? 'text-success' : 'text-error'
  const inputStyle = valid == null ? '' : valid ? 'valid' : 'invalid'
  const hintIcon = valid == null ? '' : <Icon name="icon-check-fill" size="sm" className="ml-1"/>
  
  return (
    <Modal
      active={active}
      blur={true}
      align="start"
      contentClass="p-10"
      width="400"
    >
      <style>{style}</style>
      <Text
        tag="h3"
        className="mb-3"
        weight="medium"
        size="xl"
      >
        {userInfo?.translations.switch_user_title}
      </Text>
      <Text tag="p">
        {userInfo?.translations.switch_user_subtitle}
      </Text>
      <InputPin
        value={value}
        className={`mt-10 ${inputStyle}`}
        disabled={disabled}
        onChange={onChange}
        autoFocus={true}
        data-test="pin-input"
      />
      <Text
        tag="div"
        size="xs"
        className={[hintStyle, 'my-2']}
        minHeight="14"
      >
        {hint}{hintIcon}
      </Text>
      {/* <Btn
        variant="outlined"
        className="justify-space-between border-2 mb-5"
        block={true}
        onClick={onClickButton}
      >
        <Text color="black" size="lg" weight="bold">Novedad</Text>
        <Text color="black" weight="normal">Inicia sesión con tu huella dactilar</Text>
        <Icon name="icon-bulb" size="24"/>
      </Btn> */}
      <Btn
        variant="text"
        className="p-0"
        underline={true}
        link={true}
        onClick={onClickLogout}
      >
        {userInfo?.translations.switch_user_logout_btn}
      </Btn>
    </Modal>
  )
}