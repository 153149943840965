import FarmacloudIcons from '../../assets/Farmacloud-v1.0/selection.json'
import { toKebabCase, capitalize } from '../../utils'
import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export type IconLibMaker = {
  [key: string]: (props: IconProps) => JSX.Element
}

export type IconDataJson = {
  icon: {
    paths: Array<string>
  },
  properties: {
    name: string
  }
}

const computeIconName = (name: string) => 'Icon' + capitalize(name.replace(/^icon/i, ''))

const LibIcons = FarmacloudIcons.icons.reduce((acc: IconLibMaker, data: IconDataJson) => {
  const name = toKebabCase(computeIconName(data.properties.name))
  acc[name] = (props: IconProps) => (
    <IconBase {...props}>
      <svg
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
      >
        {data.icon.paths.map((path, index) => (
          <path
            fill="currentColor"
            key={index}
            d={path}
          />
        ))}
      </svg>
    </IconBase>
  )
  return acc
}, {} as IconLibMaker)

export default LibIcons