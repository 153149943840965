import { Box } from '../common'
import type { BoxProps } from '../common/Box'
import { useState, useEffect, useMemo, createRef } from 'react'

export type InputPinProps = {
  value?: string,
  length?: number,
  disabled?: boolean,
  autoFocus?: boolean,
  onChange?: (value: string) => void
} & BoxProps

export const InputPin = ({
  className,
  value = '',
  length = 6,
  disabled,
  autoFocus,
  onChange,
  ...props
}: InputPinProps) => {

  const refs = useMemo(() => Array.from({ length }, () => createRef<HTMLInputElement>()), [length])
  const [inputs, setInputs] = useState<string[]>([])
  const [internalValue, setInternalValue] = useState<string>('')
  const [index, setIndex] = useState<number>(0)

  function setValue (val: string) {
    val = val.trim().slice(0, length)
    setInternalValue(val)
    setInputs(Array.from({ length }, (_:void, i: number) => val.charAt(i) || ''))
    onChange && onChange(val)
  }

  useEffect(() => {
    setValue(value)
  }, [length, value])

  // Manage input

  function goTo (i: number) {
    setIndex(i)
    const input = refs[i]?.current
    if (input) {
      input.focus()
      input.setSelectionRange(0, input.value.length)
    }
  }

  function goPrev () {
    goTo(Math.max(index - 1, 0))
  }

  function goNext () {
    goTo(Math.min(index + 1, length - 1))
  }

  useEffect(() => {
    if (autoFocus && !internalValue) {
      requestAnimationFrame(() => goTo(0))
    }
  }, [refs, autoFocus, internalValue])

  // Listeners

  function onInput (index: number, char: string) {
    setValue(`${internalValue.slice(0, index)}${char}${internalValue.slice(index + 1)}`)
    if (char) goTo(Math.min(inputs.indexOf('') + 1, length - 1))
  }

  function onKeyDown (e: any) {
    if (e.which === 39) {
      e.preventDefault()
      goNext()
    } else if (e.which === 37) {
      e.preventDefault()
      goPrev()
    }
  }

  function onKeyUp (e: any) {
    if (e.which === 8) {
      goPrev()
    }
  }
    
  return (
    <Box
      {...props}
      className={['input-pin', className]}
    >
      {inputs.map((val: string, i: number) => (
        <input
          key={i}
          ref={refs[i]}
          type="password"
          value={val}
          disabled={disabled}
          placeholder=" "
          onInput={(e: any) => onInput(i, e.target.value)}
          onFocus={() => setIndex(i)}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      ))}
    </Box>
  )
}