import React, { useMemo } from 'react'
import type { MergeComponentProps } from '../../utils'
import type { TextProps } from './Text'
import type { useColorsProps } from '../../hooks/useColors'
import { Text } from './Text'

type BtnRoot = 'button' | 'a'

export type BtnProps = MergeComponentProps<
  BtnRoot,
  TextProps & {
    variant?: 'default' | 'outlined' | 'text' | 'tonal',
    link?: boolean,
    block?: boolean,
    disabled?: boolean,
    clickDisabled?: boolean,
    bullet?: number | string
  }
>

export const Btn = React.forwardRef(function Btn ({
  className,
  children,
  color = 'primary',
  variant = 'default',
  weight = 'bold',
  link,
  block,
  disabled,
  clickDisabled = false,
  bullet,
  onClick,
  ...props
} : BtnProps, ref) {

  const tag = link ? 'a' : 'button'

  const colorProps: useColorsProps = {}
  if (variant === 'outlined') {
    colorProps.color = colorProps.borderColor = colorProps.bgColor = color
  } else if (variant === 'text') {
    colorProps.color = color 
    colorProps.borderColor = colorProps.bgColor = 'transparent'
  } else if (variant === 'tonal') {
    colorProps.color = colorProps.bgColor = color
    colorProps.borderColor = 'transparent'
  } else {
    colorProps.color = 'base'
    colorProps.bgColor = color
    colorProps.borderColor = 'transparent'
  }

  const handlerClick = (e: Event) => {
    if (disabled && !clickDisabled) return
    onClick && onClick(e)
  }

  const computedBullet = useMemo(() => {
    const num = parseInt(bullet + '')
    if (!num || num < 0) return null
    if (num > 99) return '+99'
    return String(num)
  }, [bullet])

  return (
    <Text
      ref={ref}
      {...colorProps}
      {...props}
      tag={tag}
      weight={weight}
      onClick={handlerClick}
      className={[
        'topbar-btn',
        `variant-${variant}`,
        block && 'block',
        disabled && 'disabled',
        clickDisabled && 'click-disabled',
        className
    ]}
    >
      {children}
      {computedBullet && (
        <Text
          className="topbar-btn-bullet"
          bgColor="primary"
          color="white"
        >
          {computedBullet}
        </Text>
      )}
    </Text>
  )
})