import { Icon, Btn, Dropdown } from '../common'
import { useMemo, useState } from 'react'
import { NotificationsPanel } from './NotificationsPanel'
import { SettingsPanel } from './SettingsPanel'
import { userInfoData } from '../../hooks/useAuth'
import { NovuProvider } from "@novu/notification-center"
import { useCount } from './UseFeeds'
import { UrlsAddressess } from '../../hooks/urls'

enum panelType {
  notifications = 'not',
  settings = 'set'
}
export const Inbox= ({ userInfo, userToken, urls } : { userInfo : userInfoData, userToken: string, urls: UrlsAddressess}) => {

  const [panelTypeState, setPanelTypeState] = useState<panelType>(panelType.notifications)
  const [open, setOpen] = useState<boolean>(false)
  const closeDropdown = () => {
    setOpen(false)
  }
  const panel = useMemo(() => {
    return (
      <div className="config-dropdown-prepend">
          <div className="notifications-inbox">
          {
            panelTypeState === panelType.notifications && 
            (<NotificationsPanel
              onSettingClick={() => setPanelTypeState(panelType.settings)}
              userInfo={userInfo}
              userToken={userToken}
              closeDropdown={closeDropdown}
              urls={urls}
            />)
          }
          {  
            panelTypeState === panelType.settings &&
            (<SettingsPanel
              onNotificationClick={() => setPanelTypeState(panelType.notifications)}
              userInfo={userInfo}
              closeDropdown={closeDropdown}
            />)
          }
          </div>
      </div>
    )
  },[panelTypeState, userInfo])

  return (
    <NovuProvider
      applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID || ''}
      subscriberId={userInfo.user_uuid}
      backendUrl={process.env.REACT_APP_NOVU_BACKEND}
      socketUrl={process.env.REACT_APP_NOVU_SOCKET || undefined}
      initialFetchingStrategy={{
        fetchUnseenCount: false,
        fetchOrganization: false,
        fetchNotifications: false,
        fetchUserPreferences: true,
      }}
    >
    {/* <Bullet/> */}
    <Dropdown
      className={['context-menu-notifications']}
      prepend={panel}
      width={500}
      offsetY={10}
      open={open}
      onActiveChange={setOpen}
    >     
      <ButtonNotifcations/>
    </Dropdown>
  </NovuProvider>

  )
}

const ButtonNotifcations = () => {
  const  { unReadCount } = useCount()
  return (
    <Btn
      data-test='notification-buttons-toolbar'
      className="rounded-0"
      variant="tonal"
      color="base"
      width="50"
      height="100%"
      bullet={unReadCount}
    >
  <Bell/>
  </Btn>
  )
}


const Bell = () => {
  return (
    <span className="bell">
      <Icon name="icon-bell-outline"
        size="xl"
      />
    </span>
  )
}




