import { Modal, Icon } from '../common'
import { FormEvent } from 'react'
import type { Theme } from '../../config'

type SearchModalProps = {
  active?: boolean,
  theme?: Theme,
  encodeFindEngineText?: boolean,
  onClose?: () => void,
  onSubmit?: (e: string) => void
}

const convertToNumericHTMLEntities = (str: string) => {
  return str.replace(/[\u00A0-\u9999<>&()"'$%]/gim, (i) => `&#${i.charCodeAt(0)};`)
}

export const SearchModal = ({
  active = false,
  theme,
  encodeFindEngineText = false,
  onClose,
  onSubmit
} : SearchModalProps) => {

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formElement = e.target as HTMLFormElement
    const inputValue = (formElement.elements.namedItem('searchTerm') as HTMLInputElement).value.trim()
    onSubmit && onSubmit(encodeFindEngineText ? convertToNumericHTMLEntities(inputValue) : inputValue)
  }
  
  return (
    <Modal
      active={active}
      theme={theme}
      align="start"
      width="460"
      bgColor="background"
      color="base"
      transparent={true}
      onClickOutside={onClose}
    >
      <form
        className="flex py-5 px-4"
        onSubmit={submitHandler}
      >
        <Icon
          name="icon-search-outline"
          className="flex-shrink-0 mx-4"
          color="primary"
          size="xl"
        />
        <input
          id="searchTerm"
          name="searchTerm"
          type="text"
          className="flex-grow-1 text-inherit bg-transparent"
          data-test="search-modal-input"
          onBlur={onClose}
          autoFocus
        />
      </form>
    </Modal>
  )
}