import { Modal, Box, Text, Btn, Icon } from '../common'
import { style } from './style'
import { userInfoData } from '../../hooks/useAuth'
import type { ApplicationInterface } from '../../hooks/auth.d.ts'

type MarketModalProps = {
  userInfo?: userInfoData,
  active?: boolean,
  onClose?: () => void
}

export const MarketModal = ({
  active = false,
  userInfo,
  onClose
} : MarketModalProps) => {

  const getLinkTarget = (app: ApplicationInterface) => {
    if (app.external) return '_blank'
    const isSelf = window.location.hostname === 'dev.farmacloud.io'
      ? ['https://dev.farmacloud.io/profile', 'https://dev.farmacloud.io/marketplace'].includes(app.slug)
      : null
    return isSelf ? '_self' : '_blank'
  }
  
  return (
    <Modal
      active={active}
      width="410"
      onClickOutside={onClose}
    >
      <style>{style}</style>
      <Icon
        name="icon-close"
        className="icon-close"
        size="24"
        onClick={onClose}
      />
      <div className="market-modal-content p-10 pt-0">
        <header className="py-10 mb-2">
          <Text
            tag="h3"
            className="title mb-2"
            size='xl'
            weight='bold'
          >
            {userInfo?.translations.title_maketplace}
          </Text>
          <Text
            tag="p"
            size='sm'
          >
            {userInfo?.translations.subtitle_maketplace}
          </Text>
        </header>
        <section data-test="market-applications">
          {userInfo?.applications?.map((app: ApplicationInterface) => (
            <Box
              key={app.slug}
              className="flex-shrink-0 mb-4"
              width="80"
            >
              <Btn
                className="border-0 mb-2 p-0"
                variant="text"
                color="black"
                width="80"
                height="80"
                link={true}
                href={app.slug}
                target={getLinkTarget(app)}
                rel="noreferrer"
                style={{ backgroundImage: `url(${app.logo})` }}
              />
              <Text
                tag="div"
                size="xs"
                align="center"
                data-test="app-name"
              >
                {app.name}
              </Text>
            </Box>
          ))}
        </section>
      </div>
    </Modal>
  )
}