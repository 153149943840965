
import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export const IconEmailBullet = (props: IconProps) => (
  <IconBase {...props}>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M17.5 5.53964C17.8568 5.3335 18.1571 5.04238 18.3741 4.69216C18.5912 4.34195 18.7184 3.94351 18.7443 3.53228C18.7702 3.12106 18.6941 2.7098 18.5227 2.3351C18.3513 1.9604 18.09 1.63387 17.7619 1.38456C17.4339 1.13525 17.0493 0.970885 16.6424 0.906098C16.2354 0.84131 15.8188 0.878104 15.4296 1.0132C15.0403 1.14831 14.6905 1.37753 14.4112 1.68047C14.1319 1.98342 13.9318 2.3507 13.8287 2.74964C13.7229 3.15959 13.7229 3.5897 13.8287 3.99964C13.9251 4.37289 14.1064 4.7188 14.3586 5.01035C14.6107 5.3019 14.9269 5.53119 15.2824 5.68029C15.6378 5.8294 16.023 5.89429 16.4077 5.86989C16.7924 5.8455 17.1662 5.73247 17.5 5.53964ZM5 2.74964H12.5512C12.4813 3.16338 12.4813 3.58591 12.5512 3.99964H5C4.66848 3.99964 4.35054 4.13134 4.11612 4.36576C3.8817 4.60018 3.75 4.91812 3.75 5.24964V6.17464L10 9.53964L14.93 6.88589C15.757 7.1967 16.6671 7.20555 17.5 6.91089V12.7496C17.5 13.4127 17.2366 14.0486 16.7678 14.5174C16.2989 14.9863 15.663 15.2496 15 15.2496H5C4.33696 15.2496 3.70107 14.9863 3.23223 14.5174C2.76339 14.0486 2.5 13.4127 2.5 12.7496V5.24964C2.5 4.5866 2.76339 3.95072 3.23223 3.48188C3.70107 3.01304 4.33696 2.74964 5 2.74964ZM3.75 7.59464V12.7496C3.75 13.0812 3.8817 13.3991 4.11612 13.6335C4.35054 13.8679 4.66848 13.9996 5 13.9996H15C15.3315 13.9996 15.6495 13.8679 15.8839 13.6335C16.1183 13.3991 16.25 13.0812 16.25 12.7496V7.59464L10.2962 10.7996C10.2052 10.8487 10.1034 10.8743 10 10.8743C9.89659 10.8743 9.7948 10.8487 9.70375 10.7996L3.75 7.59464Z" />
  </svg>

  </IconBase>
)
