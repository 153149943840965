import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export const IconRead = (props: IconProps) => (
  <IconBase {...props}>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M6.22183 9.66667H9.7785C10.1752 9.66667 10.5002 9.34167 10.5002 8.945V8.72167C10.5002 8.325 10.1752 8 9.7785 8H6.22183C5.82433 8 5.50017 8.325 5.50017 8.72167V8.945C5.50017 9.34167 5.82433 9.66667 6.22183 9.66667ZM13 13C13 13.4592 12.6267 13.8333 12.1667 13.8333H3.83333C3.37417 13.8333 3 13.4592 3 13V5.5H13V13ZM3 2.16667H13C13.46 2.16667 13.8333 2.54083 13.8333 3C13.8333 3.45917 13.46 3.83333 13 3.83333H3C2.54083 3.83333 2.16667 3.45917 2.16667 3C2.16667 2.54083 2.54083 2.16667 3 2.16667ZM15.5 3C15.5 1.62167 14.3783 0.5 13 0.5H3C1.62167 0.5 0.5 1.62167 0.5 3C0.5 3.73583 0.825833 4.39167 1.33333 4.85V13C1.33333 14.3783 2.455 15.5 3.83333 15.5H12.1667C13.545 15.5 14.6667 14.3783 14.6667 13V4.85C15.1742 4.39167 15.5 3.73583 15.5 3Z" />
  </svg>

  </IconBase>
)