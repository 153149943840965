import { Dropdown } from './common'
import { Avatar } from './Avatar'
import type { AvatarProps } from './Avatar'
import type { DropdownProps } from './common/Dropdown'
import type { DropdownItemProps } from './common/Dropdown/DropdownItem'
import { useMemo, useRef, useEffect } from 'react'
import { emitEvent } from '../utils'

export type ProfileDropdowmProps = {
  onClickProfile?: () => void,
  onClickPharmacy?: () => void,
  onClickEnv?: () => void,
  onClickLogout?: () => void
} & AvatarProps & DropdownProps

export const ProfileDropdown = ({
  className,
  userInfo,
  activeMode = 'hover',
  bgColor = 'background',
  color = 'base',
  position = 'bottom',
  align = 'end',
  width = '235',
  offsetX = '50%',
  offsetY = '20',
  onClickProfile,
  onClickPharmacy,
  onClickEnv,
  onClickLogout,
  ...props
}: ProfileDropdowmProps) => {

  const ref = useRef<HTMLDivElement>(null)

  const items = useMemo(() => [
    {
      id: 'profile',
      text: userInfo?.translations?.view_profile,
      prependIcon: 'icon-person-outline'
    },
    userInfo?.hasProfile && {
      id: 'pharmacy',
      text: userInfo?.translations?.my_pharmacy,
      prependIcon: 'icon-pharmacy'
    },
    {
      id: 'env',
      text: userInfo?.translations?.change_environment,
      prependIcon: 'icon-flip-2-outline',
      disabled: (userInfo?.organizations?.options?.length ?? 0) <= 1
    },
    {
      id: 'logout',
      text: userInfo?.translations?.close_session,
      prependIcon: 'icon-power-outline'
    }
  ].filter(Boolean), [userInfo])

  function onClickItem (item: DropdownItemProps) {
    if (item.id === 'profile') {
      onClickProfile && onClickProfile()
      emitEvent(ref?.current, 'profile')
    } else if (item.id === 'pharmacy') {
      onClickPharmacy && onClickPharmacy()
    } else if (item.id === 'env') {
      onClickEnv && onClickEnv()
    } else if (item.id === 'logout') {
      onClickLogout && onClickLogout()
    }
  }

  useEffect(() => {
    const onLogout = () => { onClickLogout && onClickLogout() }
    ref.current?.addEventListener('onLogout', onLogout)
    return () => {
      ref.current?.removeEventListener('onLogout', onLogout)
    }
  }, [ref, onClickLogout])
    
  return (
    <Dropdown
      {...props}
      ref={ref}
      className={['context-menu-profile', className]}
      activeMode={activeMode}
      bgColor={bgColor}
      color={color}
      position={position}
      align={align}
      width={width}
      offsetX={offsetX}
      offsetY={offsetY}
      items={items}
      onClickItem={onClickItem}
    >
      <Avatar
        userInfo={userInfo}
        className="flex-shrink-1 text-sm border-2"
        size="30"
        data-test="avatar"
      />
    </Dropdown>
  )
}