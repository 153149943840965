import { userInfoData } from '../hooks/useAuth'
import { Btn } from './common'
import type { BoxProps } from './common/Box'
import { useMemo } from 'react'

export type AvatarProps = {
  userInfo?: userInfoData,
  size?: string | number | null | undefined,
} & BoxProps

export const Avatar = ({
  userInfo,
  className,
  variant = 'outlined',
  color = 'primary',
  size,
  ...props
}: AvatarProps) => {

  const initial: string = useMemo(() => {
    return [
      userInfo?.information?.user_first_name ?? '',
      userInfo?.information?.user_last_name ?? ''
    ]
      .map((str) => str.charAt(0).toUpperCase())
      .join('')
  }, [userInfo])
  
  return (
    <Btn
      className={['rounded-full', className]}
      variant={variant}
      color={color}
      {...props}
      width={size}
      height={size}
    >
      {initial}
    </Btn>
  )
}