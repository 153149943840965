import { makeClassName } from '../utils'

export type useColorsProps = {
  color?: string
  bgColor?: string
  borderColor?: string
}

export function useColors ({ color, bgColor, borderColor }: useColorsProps) {
  return makeClassName([
    color && `text-${color}`,
    bgColor && `bg-${bgColor}`,
    borderColor && `border-${borderColor}`
  ])
}