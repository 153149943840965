import { Text } from './common'
import type { TextProps } from './common/Text'
import type { LanguageType } from '../hooks/auth'
import { useClock } from '../hooks/useClock'

type ClockProps = {
  language: LanguageType
} & TextProps

export const Clock = ({
  language,
  ...props
}: ClockProps) => {
  const time = useClock({ language })
  return (
    <Text {...props}>
      {time}
    </Text>
  )
}