import React from 'react'
import { makeClassName } from '../../utils'
import { useColors } from '../../hooks/useColors'
import { useDimensions } from '../../hooks/useDimensions'
import type { MergeComponentProps } from '../../utils'
import type { useColorsProps } from '../../hooks/useColors'
import type { useDimensionsProps } from '../../hooks/useDimensions'
import type { Theme } from '../../config'

export type BoxProps = MergeComponentProps<
  React.ElementType,
  {
    tag?: React.ElementType,
    className?: any,
    theme?: Theme
  } & useColorsProps & useDimensionsProps
>

export const Box = React.forwardRef(function Box ({
  tag: Component = 'div',
  className,
  children,
  color,
  bgColor,
  borderColor,
  theme,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  style,
  ...props
} : BoxProps, ref) {
  return (
    <Component
      ref={ref}
      style={{
        ...useDimensions({ width, maxWidth, minWidth, height, maxHeight, minHeight }),
        ...style
      }}
      className={makeClassName([
        'topbar-box',
        theme && `theme-${theme}`,
        useColors({ color, bgColor, borderColor }),
        className
      ])}
      {...props}
    >
      {children}
    </Component>
  )
})