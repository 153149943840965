import { useState, useEffect, useMemo } from 'react'
import { capitalize } from '../utils'
import type { Delegations, Delegation, TopbarMenu } from '../config'
import type { userInfoData } from './useAuth'

type ActiveDelegation = Delegation | null

export function useDelegations ({
  delegations,
  userInfo
}: {
  delegations?: Delegations,
  userInfo: userInfoData
}) {  
  const [activeDelegation, setActiveDelegation] = useState<ActiveDelegation>(null)

  const allDelegations = useMemo(() => {
    return Object.values((delegations?.parents) || {}).map((parent) => [
      parent.delegation_root,
      ...parent.delegations
    ]).flat().filter(Boolean)
  }, [delegations])

  const delegationsMenu = useMemo(() => {
    if (allDelegations.length <= 1) return
    const parents = Object.entries(delegations?.parents || {}).map(([uuid, parent]) => ({
      ...parent.customization,
      delegations: [parent.delegation_root, ...parent.delegations].filter(Boolean),
      uuid
    }))
    return {
      id: 'delegations',
      type: 'menu',
      options: parents.map((parent) => ({
        id: parent.name,
        type: 'menu',
        text: capitalize(parent.name || ''),
        iconLeft: 'icon-pharmacy',
        closeOnClick: true,
        menuWidth: 242,
        options: parent.delegations.map((data) => {
          const active = activeDelegation?.uuid === data.uuid
          return {
            id: data.uuid,
            type: 'event',
            text: [data.delegation_identifier, data.name].filter(Boolean).join(' - '),
            iconRight: 'icon-checkmark-outline',
            clickDisabled: true,
            disabled: !active,
            active,
            data
          }
        })
      })),
    } as TopbarMenu
  }, [
    delegations,
    allDelegations,
    activeDelegation
  ])

  useEffect(() => {
    const currentDelegation = userInfo?.current_delegation
    const activeDelegation = allDelegations.find((d) => d.uuid === currentDelegation) || null
    setActiveDelegation(activeDelegation)
  }, [userInfo, allDelegations])

  return {
    delegationsMenu,
    activeDelegation,
    setActiveDelegation
  }
}