import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export const IconArrowDown = (props: IconProps) => (
  <IconBase {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 7.99953C12.2281 7.99953 12.4551 8.07653 12.6401 8.23153L18.6401 13.2315C19.0641 13.5845 19.1221 14.2155 18.7681 14.6395C18.4151 15.0635 17.7851 15.1205 17.3601 14.7675L11.9891 10.2915L6.62709 14.6065C6.19709 14.9525 5.56709 14.8845 5.22109 14.4545C4.87509 14.0245 4.94309 13.3955 5.37309 13.0485L11.3731 8.22053C11.5561 8.07353 11.7781 7.99953 12.0001 7.99953Z" fill="#FAFAFA"/>
      <mask id="mask0_7402_1608" maskUnits="userSpaceOnUse" x="5" y="8" width="14" height="7">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 7.99953C12.2281 7.99953 12.4551 8.07653 12.6401 8.23153L18.6401 13.2315C19.0641 13.5845 19.1221 14.2155 18.7681 14.6395C18.4151 15.0635 17.7851 15.1205 17.3601 14.7675L11.9891 10.2915L6.62709 14.6065C6.19709 14.9525 5.56709 14.8845 5.22109 14.4545C4.87509 14.0245 4.94309 13.3955 5.37309 13.0485L11.3731 8.22053C11.5561 8.07353 11.7781 7.99953 12.0001 7.99953Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_7402_1608)">
      <rect x="24" y="24" width="24" height="24" transform="rotate(-180 24 24)" fill="currentColor"/>
      </g>
    </svg>
  </IconBase>
)