import { useState, useEffect, MutableRefObject } from 'react'
import { emitEvent } from '../utils'

export type useMarketModalProps = {
  ref?: MutableRefObject<HTMLDivElement | null>
}

export function useMarketModal ({
  ref
} : useMarketModalProps = {}) {

  const [openMarketModal, setOpenMarketModal] = useState<boolean>(false)

  useEffect(() => {
    openMarketModal && emitEvent(ref?.current, 'open-sidebar')
  }, [openMarketModal])

  return {
    openMarketModal,
    setOpenMarketModal
  }
}