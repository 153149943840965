import LibIcons from './icon-lib-maker'
import type { IconLibMaker } from './icon-lib-maker'
import { IconBulb } from './IconBulb'
import { IconCheckFill } from './IconCheckFill'
import { IconMarket } from './IconMarket'
import { IconClose } from './IconClose'
import { IconChevronRight } from './IconChevronRight'
import { IconRead } from './IconRead'
import { IconDelete } from './IconDelete'
import { IconArrowDown } from './IconArrowDown'
import { IconEmailBullet } from './IconEmailBullet'
import { IconEmail } from './IconEmail'
import { IconEmailOpen } from './IconEmailOpen'
import { IconBell } from './IconBell'

export default {
  ...LibIcons,
  'icon-bulb': IconBulb,
  'icon-check-fill': IconCheckFill,
  'icon-chevron-right': IconChevronRight,
  'icon-close': IconClose,
  'icon-market': IconMarket,
  'icon-read': IconRead,
  'icon-delete': IconDelete,
  'icon-arrow-down': IconArrowDown,
  'icon-email': IconEmail,
  'icon-email-bullet': IconEmailBullet,
  'icon-email-open': IconEmailOpen,
  'icon-bell': IconBell
} as IconLibMaker