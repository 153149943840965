export const style = `
.icon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #737373;
  z-index: 2;
}
.market-modal-content {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
.market-modal-content > header {
  position: sticky;
  background-color: white;
  margin-right: -5px;
  top: 0px;
  z-index: 1;
}
.market-modal-content > section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  gap: 20px;
}
.market-modal-content > section div a {
  background-size: cover;
  background-position: center;
}
`
