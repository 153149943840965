import { useMemo } from 'react'
import type { IconProps as IconBaseProps } from '../icons/IconBase'
import LibIcons from '../icons'

export type IconProps = IconBaseProps & {
  name: string
}

export const Icon = ({ name, ...props }: IconProps) => {
  const Icon = useMemo(() => LibIcons[name], [name])
  return Icon ? <Icon {...props} /> : <span />
}