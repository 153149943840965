import { Box } from '../Box'
import { style } from './style'
import type { useDimensionsProps } from '../../../hooks/useDimensions'
import type { BoxProps } from '../Box'
import { useState, useEffect } from 'react'

export type ModalProps = {
  children: React.ReactNode,
  className?: any,
  contentClass?: any,
  blur?: boolean,
  transparent?: boolean,
  active?: boolean,
  align?: 'start' | 'center' | 'end',
  onClickOutside?: (e: Event) => void,
  onClick?: (e: Event) => void
} & useDimensionsProps & BoxProps

export const Modal = ({
  className,
  contentClass,
  children,
  blur,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  align = 'center',
  active = true,
  transparent = false,
  onClick,
  onClickOutside,
  ...props
} : ModalProps) => {

  const [isHide, setIsHide] = useState<boolean>(!active)
  const [isActive, setIsActive] = useState<boolean>(false)
  const [AFRef, setAFRef] = useState<number>(0)
  const [TRef, setTRef] = useState<number>(0)

  function onClickHandler (e: Event) {
    e.stopPropagation()
    onClick && onClick(e)
  }

  useEffect(() => {
    if (active) {
      setIsHide(false)
    } else {
      setIsActive(false)
    }
  }, [active])

  useEffect(() => {
    if (!isHide) {
      clearInterval(TRef)
      window.cancelAnimationFrame(AFRef)
      setAFRef(window.requestAnimationFrame(() => setIsActive(true)))
    }
  }, [isHide])

  useEffect(() => {
    if (!isActive) {
      clearInterval(TRef)
      window.cancelAnimationFrame(AFRef)
      setTRef(window.setTimeout(() => setIsHide(true), 400))
    }
  }, [isActive])

  return (
    <div>
      <style>{style}</style>
      {!isHide && (
        <Box
          onClick={onClickOutside}
          className={[
            'topbar-modal',
            isActive && 'active',
            transparent && 'transparent',
            blur && 'blur',
            `align-${align}`,
            className
          ]}
        >
          <Box
            className={['topbar-modal-content', contentClass]}
            maxWidth={maxWidth}
            minWidth={minWidth}
            width={width}
            maxHeight={maxHeight}
            minHeight={minHeight}
            height={height}
            onClick={onClickHandler}
            {...props}
          >
            {children}
          </Box>
        </Box>
      )}
    </div>
  )
}