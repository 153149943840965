import React from 'react'
import { Box } from './Box'
import type { BoxProps } from './Box'

export type TextProps = BoxProps & {
  weight?: 'normal' | 'medium' | 'bold'
  size?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl'
  underline?: boolean
}

export const Text = React.forwardRef(function Text ({
  tag = 'span',
  weight = 'normal',
  underline = false,
  size,
  className,
  children,
  ...props
} : TextProps, ref) {
  return (
    <Box
      {...props}
      ref={ref}
      tag={tag}
      className={[
        weight && `text-${weight}`,
        size && `text-${size}`,
        underline && 'text-underline',
        className
      ]}
    >
      {children}
    </Box>
  )
})