
import { IUserPreferenceSettings, ChannelTypeEnum } from "@novu/notification-center"

import { Icon, Text, Box, Switch, Accordion, AccordionItem } from '../common'
import { userInfoData } from '../../hooks/useAuth';
import { IconEmail } from '../icons/IconEmail';
import { IconSms } from '../icons/IconSms';
import { useSettings } from './UseSettings';

export const SettingsPanel = ({
    onNotificationClick,
    userInfo,
    closeDropdown
}: {
    onNotificationClick: () => void
    userInfo: userInfoData,
    closeDropdown: () => void
}) => {
  const {
    settings,
    updateUserSettings
  } = useSettings()
    return (
        <div>
        <Header
          onNotificationClick={onNotificationClick}
          userInfo={userInfo}
          closeDropdown={closeDropdown}
        />
        <Body
          settings={settings}
          updateUserSettings={updateUserSettings}
          userInfo={userInfo}
        />
      </div>
    )
}

const Header = ({
  onNotificationClick,
  userInfo,
  closeDropdown
}: {
  onNotificationClick: () => void,
  userInfo: userInfoData,
  closeDropdown: () => void
}) => {
    return (
    <Box className='notifications-header-settings'>
      <Box onClick={onNotificationClick} className='notification-header-right'>
        <Icon 
          style={{
            transform: 'rotate(180deg)',
            color: 'white',
            marginBottom: '-4px',
            cursor: 'pointer'
          }}
          size={30}
          name="icon-chevron-right"
        />
        <Text
          size="xl"
          weight="bold"
          style={{ cursor:'pointer' }}
        >
          {userInfo.translations.notifications_settings_subtitle}
        </Text>
      </Box>
      <Icon 
        style={{
          color: 'white',
          cursor:'pointer'
        }}
        size={24}
        onClick={closeDropdown}
        name="icon-close"
      />
    </Box>
    )
}

const Body = ({
  settings,
  updateUserSettings
}: {
  settings: IUserPreferenceSettings[]
  updateUserSettings: (id: string, channel: string, value: boolean) => void
  userInfo: userInfoData
}) => {
  
  return (
  <Box className="notifications-body"> 
  <Accordion>
  {
    settings
      .filter((s) => !s.template.critical && Object.keys(s.preference.channels).length > 0)
      .map((item: IUserPreferenceSettings) => {
      return (
        <SettingsItem
          key={item.template._id}
          item={item}
          updateUserSettings={updateUserSettings}
        />
      )
    })
  } 
  </Accordion>
  
  </Box>)
}

const SettingsItem = ({
  item,
  updateUserSettings
}: {
  item: IUserPreferenceSettings
  updateUserSettings: (id: string, channel: string, value: boolean) => void

}) => {
  const onChangeInput = (id: string, channel: string, value: boolean) => {
    updateUserSettings(id, channel, value)
  }

  const stringTransformation = (channel: string) => {
    const channelStr = channel.replace('_', ' ')
    return channelStr.charAt(0).toUpperCase() + channelStr.slice(1)
  }

  const getChannelsString = () => {
    const channelString = Object.keys(item.preference.channels).map(channel => stringTransformation(channel)).join(', ')
    return  channelString || undefined;
  }

  return (
    <AccordionItem
      key={item.template._id}
      title={item.template.name}
      subtitle={getChannelsString()}
      classNameWrapper="notifications-settings-template"
      classNameHeader="notifications-settings-template-header"
    >
      {
        Object.keys(item.preference.channels).map((channel: string) => {
          const channelTyped: ChannelTypeEnum = channel as ChannelTypeEnum
          return (
            <AccordionItemContent channelTyped={channelTyped} key={channelTyped} item={item} channel={channel} onChangeInput={onChangeInput}/>
          )
        })
      }
   </AccordionItem>
  )
}

const AccordionItemContent = ({ item, channel, onChangeInput, channelTyped }: {
  item: IUserPreferenceSettings,
  channelTyped: ChannelTypeEnum,
  channel: string,
  onChangeInput: (id: string, channel: string, value: boolean) => void
}) => {

  const channelIconMap = new Map([
    ['email', IconEmail],
    ['push', IconEmail],
    ['sms', IconSms],
    ['in_app', IconEmail]
  ])

  const ChannelIcon = channelIconMap.get(channel) || IconEmail

 return (
    <Box
      key={`${item.template._id}-${channel}`}
      className="notifications-settings-channels-item"
    >
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', columnGap: 12}}>
        <ChannelIcon />
        <Text className="channel-name" size="xl" weight="normal">
          {channelTyped.replace('_', ' ')}
        </Text>
      </div>
      <Switch
        onChange={value => onChangeInput(item.template._id, channel, value)}
        checked={item.preference.channels[channelTyped]}
      />
    </Box>
  )
}