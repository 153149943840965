
import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export const IconEmailOpen = (props: IconProps) => (
  <IconBase {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path  fill="currentColor" d="M11.4094 1.63935C10.9811 1.39134 10.4949 1.26074 10 1.26074C9.50508 1.26074 9.01892 1.39134 8.59062 1.63935L2.65313 5.07685C2.22625 5.32404 1.8719 5.67914 1.62562 6.10654C1.37935 6.53393 1.24981 7.01858 1.25 7.51185V14.0625C1.25 14.8084 1.54632 15.5238 2.07376 16.0512C2.60121 16.5787 3.31658 16.875 4.0625 16.875H15.9375C16.6834 16.875 17.3988 16.5787 17.9262 16.0512C18.4537 15.5238 18.75 14.8084 18.75 14.0625V7.51123C18.75 7.01817 18.6203 6.53378 18.3741 6.10662C18.1278 5.67946 17.7736 5.32456 17.3469 5.07748L11.4094 1.63935ZM9.21688 2.72123C9.45485 2.58339 9.72499 2.51081 10 2.51081C10.275 2.51081 10.5451 2.58339 10.7831 2.72123L16.7206 6.15873C16.9313 6.2806 17.1063 6.44748 17.2369 6.6431L10 10.54L2.76313 6.64373C2.89438 6.4481 3.06938 6.28123 3.27938 6.15935L9.21688 2.72123ZM2.5 7.92123L9.70375 11.8006C9.7948 11.8496 9.89659 11.8753 10 11.8753C10.1034 11.8753 10.2052 11.8496 10.2963 11.8006L17.5 7.92123V14.0625C17.5 14.4769 17.3354 14.8743 17.0424 15.1673C16.7493 15.4604 16.3519 15.625 15.9375 15.625H4.0625C3.6481 15.625 3.25067 15.4604 2.95765 15.1673C2.66462 14.8743 2.5 14.4769 2.5 14.0625V7.92123Z"/>
    </svg>
  </IconBase>
)
