import { IconBase } from './IconBase'
import type { IconProps } from './IconBase'

export const IconBulb = (props: IconProps) => (
  <IconBase {...props}>
    <svg
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M13.3047 5.85156C13.3047 6.40456 12.8567 6.85156 12.3047 6.85156C11.7527 6.85156 11.3047 6.40456 11.3047 5.85156V3.85156C11.3047 3.29856 11.7527 2.85156 12.3047 2.85156C12.8567 2.85156 13.3047 3.29856 13.3047 3.85156V5.85156ZM13.3047 16.3114C13.3047 15.9544 13.4957 15.6234 13.8057 15.4444C14.7307 14.9134 15.3047 13.9194 15.3047 12.8514C15.3047 11.1974 13.9587 9.85136 12.3047 9.85136C10.6507 9.85136 9.30469 11.1974 9.30469 12.8514C9.30469 13.9194 9.87869 14.9134 10.8037 15.4444C11.1137 15.6234 11.3047 15.9544 11.3047 16.3114V20.8514H13.3047V16.3114ZM7.30469 12.8514C7.30469 10.0944 9.54769 7.85136 12.3047 7.85136C15.0617 7.85136 17.3047 10.0944 17.3047 12.8514C17.3047 14.4304 16.5507 15.9134 15.3047 16.8474V20.8514C15.3047 21.9544 14.4077 22.8514 13.3047 22.8514H11.3047C10.2017 22.8514 9.30469 21.9544 9.30469 20.8514V16.8474C8.05769 15.9134 7.30469 14.4304 7.30469 12.8514ZM21.3047 11.8516H19.3047C18.7517 11.8516 18.3047 12.2986 18.3047 12.8516C18.3047 13.4046 18.7517 13.8516 19.3047 13.8516H21.3047C21.8577 13.8516 22.3047 13.4046 22.3047 12.8516C22.3047 12.2986 21.8577 11.8516 21.3047 11.8516ZM3.30469 11.8516H5.30469C5.85669 11.8516 6.30469 12.2986 6.30469 12.8516C6.30469 13.4046 5.85669 13.8516 5.30469 13.8516H3.30469C2.75269 13.8516 2.30469 13.4046 2.30469 12.8516C2.30469 12.2986 2.75269 11.8516 3.30469 11.8516ZM7.96439 7.26956L6.52539 5.87956C6.12839 5.49656 5.49639 5.50856 5.11139 5.90556C4.72739 6.30156 4.73839 6.93556 5.13539 7.31956L6.57439 8.70856C6.76939 8.89656 7.01939 8.98956 7.26939 8.98956C7.53139 8.98956 7.79239 8.88656 7.98839 8.68356C8.37239 8.28756 8.36139 7.65356 7.96439 7.26956ZM18.0841 5.88126C18.4801 5.49826 19.1161 5.50926 19.4971 5.90526C19.8811 6.30126 19.8701 6.93526 19.4731 7.31826L18.0341 8.70826C17.8411 8.89526 17.5901 8.98926 17.3401 8.98926C17.0781 8.98926 16.8161 8.88626 16.6211 8.68326C16.2371 8.28726 16.2481 7.65326 16.6451 7.27026L18.0841 5.88126Z"
      />
    </svg>
  </IconBase>
)