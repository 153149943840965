import { Icon, Text, Box, Tag, Btn } from '../common'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { userInfoData } from '../../hooks/useAuth';
// import { Feed } from './NotificationsHook'
import Slider from "react-slick"
import { UrlsAddressess } from '../../hooks/urls'

import { useFeeds, Feed, Notification } from './UseFeeds'
import {  ReactElement, RefObject, useState, useEffect, createRef, useRef } from 'react';


export const NotificationsPanel = ({
    onSettingClick,
    userInfo,
    userToken,
    closeDropdown,
    urls
  }: {
    onSettingClick: () => void
    closeDropdown: () => void
    userInfo: userInfoData
    userToken: string
    urls: UrlsAddressess
  }) => {
    const {
      selectFeed,
      fetchNextPage,
      selectIsRead,
      unReadCountTab,
      feeds,
      hasNextPage,
      notifications,
      isLoading,
      isRead,
      markAsRead,
      markAllAsRead,
      deleteNotification,
      deleteAllNotifications,
      markAsUnRead,
      selectedFeed
    } = useFeeds({ userToken: userToken, urls:urls })

    return (
      <div>
        <Header
          markAllAsRead={markAllAsRead}
          unReadCountTab={unReadCountTab}
          onSettingClick={onSettingClick}
          userInfo={userInfo}
          isRead={isRead}
          selectIsRead={selectIsRead}
          deleteAllNotifications={deleteAllNotifications}
          feeds={feeds}
          selectFeed={selectFeed}
          selectedFeed={selectedFeed}
          closeDropdown={closeDropdown}
        />
        <Body
          notifications={notifications}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          markAsRead={markAsRead}
          deleteNotification={deleteNotification}
          isRead={isRead}
          userInfo={userInfo}
          markAsUnRead={markAsUnRead}
          selectedFeed={selectedFeed}
        />
      </div>
    );
  }
  
  
  const Header = ({
    markAllAsRead,
    onSettingClick,
    unReadCountTab,
    userInfo,
    isRead,
    selectIsRead,
    deleteAllNotifications,
    feeds,
    selectFeed,
    selectedFeed,
    closeDropdown
  }: {
    markAllAsRead: () => void,
    unReadCountTab: string,
    onSettingClick: () => void,
    userInfo: userInfoData,
    isRead: boolean,
    feeds: Feed[],
    selectIsRead: (read: boolean) => void,
    deleteAllNotifications: () => void,
    selectFeed: (feed: Feed) => void,
    selectedFeed: Feed,
    closeDropdown: () => void,
  }) => {
    const [hasArrows, setHasArrows] = useState<boolean>(false)
    return (
      <Box className='notifications-header'>
        <Box className="notifications-header-top">
          <Text
            color="white"
            weight="bold"
            size="xl"
            >{userInfo.translations.notifications_inbox_title}
          </Text>
          <Box className='notification-hader-right'>
            <Icon
              onClick={onSettingClick}
              title="Ajustes"
              color="white"
              className="notifications-settings"
              name="icon-settings-2-outline"
              style={{ cursor:'pointer'}}
            />
            <Icon 
              style={{
                color: 'white',
                cursor:'pointer'
              }}
              size={28}
              onClick={closeDropdown}
              name="icon-close"
            />
          </Box>
        </Box>
        <Box length={feeds.length} className="notifications-header-tags">
          {
            feeds.length && (
              <div className='slider-container-tag'>
                <Slider {...{
                    appendDots:(dots: ReactElement[]) => {
                      setHasArrows((dots?.length) ? true : false)
                      return <div></div>
                    },
                    dots: true,
                    infinite: false,
                    autoplay: false,
                    speed: 500,
                    slidesToScroll: 1,
                    slidesToShow: 4,
                    variableWidth: true,
                    prevArrow: <div></div>,
                    nextArrow: (hasArrows) ? <div><Icon className="bg-background" size="25" name="icon-arrow-down"/></div> : <div></div>
                  }}>
                  <Tag
                    key={selectedFeed.identifier}
                    color={selectedFeed.selected ? 'primary' : 'secondary'}
                    onClick={() => selectFeed(selectedFeed)}
                  >{ selectedFeed.name}</Tag>
                {
                  feeds.filter((f) => f.identifier !== selectedFeed.identifier).map((feed) => {
                    return (
                      <Tag
                        key={feed.identifier}
                        className="slider-tag"
                        color={feed.selected ? 'primary' : 'dark-grey'}
                        onClick={() => selectFeed(feed)}
                      >{ feed.name}</Tag>
                  )
                  })
                }
                </Slider>
              </div>
            )
          }
        </Box>
        <Box className="notifications-header-bottom">
            <Box>
              <Text
                onClick={() => selectIsRead(false)}
                size="md"
                weight="bold"
                className={(isRead ? 'tab' : ' tab active')}
              > { userInfo.translations.notifications_inbox_tab_unread}
              { unReadCountTab && (
                    <Text
                    className="notifications-unread"
                    bgColor="primary"
                    color="white"
                    >
                      {unReadCountTab}
                    </Text>)
                }
              </Text>
              <Text
                className={(isRead ? 'tab active' : 'tab')}
                onClick={() => selectIsRead(true)} 
                size="md" 
                weight="bold"
              >{ userInfo.translations.notifications_inbox_tab_read}</Text>
            </Box>
            {
              (isRead) ? 
              (<Text className="tab tab-icon" size="md" weight="bold" onClick={deleteAllNotifications}> 
                
                {userInfo.translations.notifications_set_as_remove}
                <Icon 
                   style={{
                     color: 'white',
                     cursor:'pointer'
                   }}
                   name="icon-delete"
                   size={20}
                 />
              </Text>)
              : (
                <Text className="tab tab-icon" size="md" weight="bold" onClick={markAllAsRead}>
                 {userInfo.translations.notifications_set_as_read}
                 <Icon 
                   style={{
                     color: 'white',
                     cursor:'pointer'
                   }}
                   name="icon-email-open"
                   size={20}
                 />
                </Text>
              )
            }
        </Box>
      </Box>
    )
  }
  
  const Body = ( {
    notifications,
    isLoading,
    hasNextPage,
    isRead,
    userInfo,
    fetchNextPage,
    markAsRead,
    deleteNotification,
    markAsUnRead,
    selectedFeed
  }: {
    notifications: Notification[] | undefined,
    isLoading: boolean,
    hasNextPage: boolean | undefined
    isRead: boolean
    userInfo: userInfoData
    fetchNextPage: () => void
    markAsRead: (notification: Notification) => void
    markAsUnRead: (notification: Notification) => void
    deleteNotification: (notification: Notification) => void
    selectedFeed: Feed
  } ) => {
    const [sentryRef] = useInfiniteScroll({
      loading: isLoading ,
      hasNextPage: hasNextPage || false,
      onLoadMore: fetchNextPage,
      rootMargin: '0px 0px 20px 0px',
    });
    const bodyRef = createRef<HTMLDivElement>()
    useEffect(() => {
      bodyRef.current?.scrollTo(0, 0)
    }, [isRead, selectedFeed])
    return (
      <Box
        ref={bodyRef}
        className='notifications-body'
      >
       
      {
        Array.isArray(notifications) && notifications?.length > 0 ? (
          <div className="notifications-body-inner">
            {notifications?.map((notification: Notification) => {
              return (
                <NotificationItem
                  key={notification._id}
                  ref={notification.ref}
                  notification={notification}
                  markAsRead={markAsRead}
                  deleteNotification={deleteNotification}
                  userInfo={userInfo}
                  markAsUnRead={markAsUnRead}
                />

              )
            })}
  
            <div className='sentry' style={{height: '1px' }}ref={sentryRef}/>
            {isLoading && (
              <div className='notification-is-loading'>{userInfo.translations.notifications_loading + String(isLoading) + String(hasNextPage)} </div>
            )}
          </div>
          ) : (
          <Box
            className="notification-empty-message"
          >
            <EmptyList isRead={isRead} userInfo={userInfo}></EmptyList>
          </Box>)
      }
    </Box>
  
    )
  }
  
  const NotificationItem = ({
    notification,
    markAsRead,
    deleteNotification,
    userInfo,
    markAsUnRead,
    ref,
  }: {
    notification: Notification,
    markAsRead: (notification: Notification) => void
    markAsUnRead: (notification: Notification) => void
    deleteNotification: (notification: Notification) => void
    userInfo: userInfoData
    ref: RefObject<HTMLDivElement> | null
  }) => {
    const now = new Date().getTime()
    const createdDate = new Date(notification.createdAt)
    const created = createdDate.getTime()
    
    const diffTime = Math.abs(now - created);
    const diffDays = Math.trunc(Math.floor(diffTime / (1000 * 60 * 60 * 24))) 
    const diffHours =Math.trunc(diffTime / 36e5)
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    const  formatDate = new Date(notification.createdAt).toLocaleDateString('ca', options) 
    const formatDateTime = formatDate + `  ${createdDate.getHours()}:${createdDate.getMinutes()}`
    let fmtTime
    if (diffHours < 1 ) {
      fmtTime = userInfo.translations.notifications_less_one_hour
    } else if (diffHours < 24) { // meno de 1 dia
      fmtTime = `Hace ${diffHours} horas`
    } else if(diffDays === 1) { // meno de una semana
      fmtTime = `Hace ${diffDays} día`
    } else if (diffDays >= 2 && diffDays < 15) {
      fmtTime = `Hace ${diffDays} días`
    } else {
      fmtTime = formatDateTime
    }
    const [isReadChange, setIsReadChange] = useState<boolean>(false)
    const firstUpdateRead = useRef(false);
    useEffect(() => {
      if (firstUpdateRead.current) {
        setIsReadChange(true)
      }
      firstUpdateRead.current = true
      console.log(notification._id, notification.read, '----------___>')
    }, [notification.read])

    const [isDeletingChange, setIsDeletingChange] = useState<boolean>(false)
    const firstUpdateDeleting = useRef(false);
    useEffect(() => {
      if (firstUpdateDeleting.current) {
        setIsDeletingChange(true)
      }
      firstUpdateDeleting.current = true
    }, [notification.deleting])
    return (
        <Box
          className={
            `notifications-notification ${(notification.read) ? 'is-read' : 'not-read'} 
             ${(isReadChange) ? 'notification-exit-transition ' : 'not-change'} 
             ${(isDeletingChange) ? 'notification-exit-transition ' : 'not-change'} 
            `
          }
          ref={ref}
        >
          <Box className="line"></Box>
          <Box className="avatar" style={{backgroundImage: `url("${notification.actor?.data}")`}}></Box>
          <Box className='content'>
            <Box
              className="content-top"
            >
              <Box className="content-top-left">
                  <Text dangerouslySetInnerHTML={{__html: notification.content}}></Text>
              </Box>
              <Box className="content-top-right">
                {
                  !notification.read &&
                  (<Icon
                    onClick={() => markAsRead(notification)}
                    title={userInfo.translations.notifications_set_as_read_tip}
                    className="close"
                    name="icon-email-open"
                    size={20}
                    color="white"
                    style={{
                      cursor: 'pointer'
                    }}
                  />)
                }
                {
                  notification.read &&
                  (
                  <div>
                  <Icon
                    onClick={() => markAsUnRead(notification)}
                    title={userInfo.translations.notifications_set_as_unread_tip}
                    className="close"
                    name="icon-email-bullet"
                    size="xl"
                    style={{
                      cursor: 'pointer',
                      marginRight: '5px',
                    }}
                  />
                  <Icon
                    onClick={() => deleteNotification(notification)}
                    title={userInfo.translations.notifications_set_as_remove_tip}
                    className="close"
                    name="icon-delete"
                    size="xl"
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                  </div>
                )

                }
              </Box>
            </Box>
            <Box className="content-bottom">
                <Box className="content-bottom-left">
                  {
                    notification.cta && notification.cta?.action?.buttons && (
                      <Box className="button-box">
                        {
                          notification.cta?.action?.buttons.map((button) => {
                            return (
                              <Btn
                                key={button.type}
                                color={button.type}
                                underline={true}
                                weight="bold"
                                className="action-button"
                                onClick={() => {
                                  window.open(notification.payload[userInfo.language][button.type], '_blank')
                                }}
                              >
                                {button.content}
                              </Btn>  
                            )
                          })
                        }
                      </Box>
                    )
                  }
                </Box>
                <Box className="content-bottom-right">
                  <Text title={formatDateTime} size="xs">{fmtTime}</Text>
                </Box>
            </Box>

          </Box>


        </Box>
    )
  }


  const EmptyList = ({ isRead, userInfo }: { isRead: boolean, userInfo: userInfoData}) => {
    return (<Box className="empty-list">
      <Box className="empty-list-icon">
        <Icon name="icon-bell" color="primary" size="50"/>
      </Box>
      <Box>
      <Text className="empty-list-text-title"  size="xl" weight="bold"> 
      {
        isRead ? userInfo.translations.notifications_empty_unread_notifications : userInfo.translations.notifications_empty_notifications
      }
      </Text>
      <Box className="empty-list-text-description">
        <Text>{ userInfo.translations.notifications_empty_message }</Text>
      </Box>
      </Box>
    </Box>)
  }