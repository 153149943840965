export const style = `
.icon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #737373;
}
.section-organizations {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}
.section-organizations > div {
  padding: 9px;
  border: 1px solid #E5E5E5;  
  border-radius: 4px;
  overflow: hidden;
}
.section-organizations > div > img {
  width: 115px;
  height: auto;
}
.section-organizations > div.disabled {
  opacity: 0.5;
  pointer-events: none !important;
}
.section-organizations > div:not(.disabled) {
  cursor: pointer;
}
.section-organizations > div:not(.disabled):hover {
  border: 1px solid #000;
}
`
